<template>
  <div>
    <table class="table table-bordered table-info">
      <thead>
        <tr>
          <th width="40">ลำดับ</th>
          <th width="100">เลขที่</th>
          <th width="180" class="pb-1">ชื่อใช้งาน
            <b-form-checkbox
              v-model="showName"
              :value="true"
              :unchecked-value="false"
              class="mt-2 font-weight-normal"
            >
              แสดงชื่อ
            </b-form-checkbox>
          </th>
          <th width="120">งวด</th>
          <th width="140">อัตราจ่าย</th>
          <th width="180">เวลา</th>
          <th width="100">รายการ</th>
          <th width="100">ยอดบิล</th>
          <th width="100">ส่วนลด</th>
          <th width="100">สุทธิ</th>
          <th width="120">สถานะ</th>
          <th>หมายเหตุ</th>
          <th width="60"></th>
        </tr>
      </thead>
      <tbody v-for="group in groups" :key="group.round.roundId">
        <tr>
          <th colspan="6" class="alert-warning">{{group.market.groupTitle}} ✦ {{group.market.marketTitle}}</th>
          <th class="text-center alert-warning">{{group.summary.length}}</th>
          <th class="text-right alert-warning text-success">{{group.summary.amount | amountFormat}}</th>
          <th class="text-right alert-warning text-danger">-{{group.summary.discount | amountFormat}}</th>
          <th class="text-right alert-warning text-success">{{group.summary.credit | amountFormat}}</th>
          <th colspan="3" class="alert-warning"></th>
        </tr>
        <tr v-for="(ticket, index) in group.tickets" :key="ticket._id">
          <td class="text-center">{{index+1}}</td>
          <td class="text-center">{{ticket.ticketNo}}</td>
          <td class="text-center">
            {{ ticket.account.mainUser.username }}
            <small v-if="showName" class="text-secondary">({{ticket.account.mainUser.firstName}})</small>
          </td>
          <td class="text-center">{{ticket.round.roundDate}}</td>
          <td class="text-center">{{ticket.rate.rateTitle}}</td>
          <td class="text-center">
            {{ticket.createdAt | dispDateTime("DD/MM/YYYY HH:mm:ss")}}
          </td>
          <td class="text-center">{{ticket.summary.length}}</td>
          <td class="text-right text-success">{{ticket.summary.amount | amountFormat}}</td>
          <td class="text-right text-danger">-{{ticket.summary.discount | amountFormat}}</td>
          <td class="text-right text-success">{{ticket.summary.credit | amountFormat}}</td>
          <td class="text-center">
            <TicketStatus :status="ticket.status" />
          </td>
          <td class="text-center">{{ticket.remarks}}</td>
          <td class="text-center">
            <button class="btn btn-outline-info btn-sm" @click="viewTicket(ticket._id)"><i class="fas fa-list"></i></button>
          </td>
        </tr>
      </tbody>
      <tbody v-if="isLoading">
        <tr>
          <td colspan="12" class="text-center">กรุณารอซักครู่</td>
        </tr>
      </tbody>
    </table>

    <ViewTicketModal :is-show="isShowTicketModal" :ticket-id="viewTicketId" @close="isShowTicketModal=false" />
  </div>
</template>
<script>
import ReportService from "@/services/ReportService"
import Swal from 'sweetalert2'
import moment from '@/helpers/moment'
import _ from 'lodash'
import ViewTicketModal from './ViewTicketModal'
import cAlert from '@/helpers/alert'

export default {
  name: 'MemberTickets',
  components: {
    ViewTicketModal
  },
  props: ['accountId', 'roundId'],
  data() {
    return {
      isLoading: false,
      data: null,
      showName: false,
      isShowTicketModal: false,
      viewTicketId: null
    }
  },
  computed: {
    changed() {
      return `${this.accountId}-${this.roundId}`
    },
    groups() {
      if(!this.data)
        return []

      const groups = _.groupBy(this.data.tickets, (ticket)=>{
        return ticket.round.roundId
      })

      const rounds = []
      for(const roundId in groups) {
        const summary = groups[roundId].reduce((total, item)=>{
          total.amount += item.summary.amount
          total.credit += item.summary.credit
          total.discount += item.summary.discount
          total.length += item.summary.length
          return total
        }, {
          amount: 0,
          credit: 0,
          discount: 0,
          length: 0
        })

        rounds.push({
          round: groups[roundId][0].round,
          market: groups[roundId][0].market,
          tickets: groups[roundId],
          summary: summary
        })
      }

      return _.orderBy(rounds, ['round.close'], ['asc'])
    }
  },
  watch: {
    changed() {
      this.getReport()
    }
  },
  methods: {
    getReport() {

      if(!this.accountId)
        return

      this.isLoading = true
      ReportService.getMemberTickets(this.accountId, this.roundId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
          this.$emit('Breadcrumbs', response.data.member.parents)
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    viewTicket(ticketId) {
      this.isShowTicketModal = true
      this.viewTicketId = ticketId
    }
  },
  mounted() {
    this.getReport()
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
        font-weight: 500;
        line-height: 1;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        padding: 5px;
        vertical-align: middle;
        font-size: 90%;
      }
      th {
        padding: 5px;
        font-size: 95%;
      }
    }
  }
  tfoot {
    tr {
      td {
        font-size: 90%;
      }
    }
  }
}
</style>
<style lang="scss">
.table-wait-account {
  tbody {
    tr {
      td {
        .badge {
          font-size: 90%;
          font-weight: normal;
        }
      }
    }
  }
}
</style>

<template>
  <div>
    <table class="table-wait-account table table-bordered table-warning table-sm mb-0">
      <thead>
        <tr>
          <th rowspan="2">ชื่อใช้งาน</th>
          <th width="12%" rowspan="2">ระดับ</th>
          <th width="10%" rowspan="2" style="border-right: 2px solid #ffdf7e;">ยอดทั้งหมด</th>
          <th colspan="3" style="border-right: 2px solid #ffdf7e;">สมาชิก</th>
          <th colspan="3" style="border-right: 2px solid #ffdf7e;">{{agentName}}</th>
          <th colspan="3">บริษัท</th>
        </tr>
        <tr>
          <th width="6%" class="p-1">ส่งออก</th>
          <th width="6%" class="p-1">คอมฯ</th>
          <th width="6%" class="p-1" style="border-right: 2px solid #ffdf7e;">รวม</th>
          <th width="6%" class="p-1">ถือหุ้น</th>
          <th width="6%" class="p-1">คอมฯ</th>
          <th width="6%" class="p-1" style="border-right: 2px solid #ffdf7e;">รวม</th>
          <th width="6%" class="p-1">ถือหุ้น</th>
          <th width="6%" class="p-1">คอมฯ</th>
          <th width="6%" class="p-1">รวม</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in accounts" :key="item.childAccountId">
          <td class="p-0 px-1 text-center">
            <a v-if="item.agent.role==='Agent'" href="#" @click="toggleChildAccount(item.agent.childAccountId)">{{ item.agent.username }}</a>
            <a v-else href="#" @click="toggleMemberAccount(item.agent.memberAccountId)">{{ item.agent.username }}</a>
            <small v-if="showName" class="text-secondary ml-1">({{item.agent.firstName}})</small>
          </td>
          <td class="text-center">
            <AccountRole :role="item.agent.role" />
          </td>
          <td class="text-right px-1 text-success" style="border-right: 2px solid #ffdf7e;">{{ item.summary.amount | amountFormat(2, '0.00') }}</td>
          <td class="text-right px-1 text-danger">{{ item.summary.memberAmount | amountFormat(2, '0.00') }}</td>
          <td class="text-right px-1 text-success">{{ item.summary.memberCommission | amountFormat(2, '0.00') }}</td>
          <td class="text-right px-1 text-danger" style="border-right: 2px solid #ffdf7e;">{{ item.summary.memberTotal | amountFormat(2, '0.00') }}</td>
          <td class="text-right px-1"
            :class="[{
              'text-success': item.summary.agentAmount>0
            }, {
              'text-danger': item.summary.agentAmount<0
            }]">{{ item.summary.agentAmount | amountFormat(2, '0.00') }}</td>
          <td class="text-right px-1"
            :class="[{
              'text-success': item.summary.agentCommission>0
            }, {
              'text-danger': item.summary.agentCommission<0
            }]">{{ item.summary.agentCommission | amountFormat(2, '0.00') }}</td>
          <td class="text-right px-1" style="border-right: 2px solid #ffdf7e;"
            :class="[{
              'text-success': item.summary.agentTotal>0
            }, {
              'text-danger': item.summary.agentTotal<0
            }]">{{ item.summary.agentTotal | amountFormat(2, '0.00') }}</td>
          <td class="text-right px-1"
            :class="[{
              'text-success': item.summary.companyAmount>0
            }, {
              'text-danger': item.summary.companyAmount<0
            }]">{{ item.summary.companyAmount | amountFormat(2, '0.00') }}</td>
          <td class="text-right px-1"
            :class="[{
              'text-success': item.summary.companyCommission>0
            }, {
              'text-danger': item.summary.companyCommission<0
            }]">{{ item.summary.companyCommission | amountFormat(2, '0.00') }}</td>
          <td class="text-right px-1"
            :class="[{
              'text-success': item.summary.companyTotal>0
            }, {
              'text-danger': item.summary.companyTotal<0
            }]">{{ item.summary.companyTotal | amountFormat(2, '0.00') }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'WaitRewardByAccount',
  props: ['data', 'roundId', 'showName'],
  computed: {
    agentName() {
      if(!this.data)
        return ''

      return this.data?.agent?.username
    },
    accounts() {
      if(!this.data)
        return []

      const reports = this.data.reports.filter((report)=>{
        return report.round.roundId === this.roundId
      })
      .map((report)=>{

        const child = this.data.childs.find((child)=>{
          return child.memberAccountId === report._id.accountId
        })

        const account = this.data.accounts.find((account)=>{
          return account.childAccountId === child.childAccountId
        })

        return {
          ...report,
          agent: account
        }
      })

      const groups = _.groupBy(reports, (report)=>{
        return report.agent.childAccountId
      })

      const accounts = []
      for(const accountId in groups) {

        const summary = groups[accountId].reduce((total, item)=>{
          total.amount += item.amount
          total.memberAmount += item.memberAmount
          total.memberCommission += item.memberCommission
          total.memberTotal += item.memberTotal
          total.agentAmount += item.agentAmount
          total.agentCommission += item.agentCommission
          total.agentTotal += item.agentTotal
          total.companyAmount += item.companyAmount
          total.companyCommission += item.companyCommission
          total.companyTotal += item.companyTotal
          return total
        }, {
          amount: 0,
          memberAmount: 0,
          memberCommission: 0,
          memberTotal: 0,
          agentAmount: 0,
          agentCommission: 0,
          agentTotal: 0,
          companyAmount: 0,
          companyCommission: 0,
          companyTotal: 0
        })

        accounts.push({
          agent: groups[accountId][0].agent,
          summary: summary
        })
      }

      return _.orderBy(accounts, ['agent.username'], ['asc'])
    },
  },
  methods: {
    toggleChildAccount(accountId) {
      this.$emit('toggleAccount', accountId)
    },
    toggleMemberAccount(accountId) {
      this.$emit('toggleMember', accountId, this.roundId)
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
        font-weight: 500;
        font-size: 90%;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        padding: 5px;
        vertical-align: middle;
        font-size: 80%;
      }
    }
  }
}
</style>
